body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-blob {
	border-radius: 50%;
	margin: 10px;
	height: 15px;
	width: 15px;
	position: absolute;
	top: 0;
	right: 0;
	box-shadow: 0 0 0 0 #6DFFB6;
	transform: scale(1);
	animation: customPulse 2s infinite;
}

@keyframes customPulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #6DFFB6;
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px #6dffb600;
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #6dffb600;
	}
}

.notification-badge {
	padding: 0px 6px;
	background-color: red;
	color: white;
	font-weight: 600;
	position: absolute;
	right: 32%;
	border-radius: 50%;
}
.sidebar-compact .notification-badge {
	top: 0;
}